<template>
<div class="s2CellBGView">
  <device-msg-head :order="order"
                   device-type="S2"
                   :device-mac="s2Info.deviceMac"
                   :device-station-mac="'基站:' + s2Info.baseStation"
  ></device-msg-head>
  <div style="height: 5px"></div>
  <device-msg-cell v-for="(key) in dataList" :key="key" :content-str="key + ':' + dataMsg(key)">
  </device-msg-cell>
  <device-msg-footer :rssi="dataMsg('rssi')" :time-stamp="dataMsg('dataTime')"></device-msg-footer>
  <div style="width: 95%;height: 1px;background-color: #cccccc;margin: 5px 0px 5px 0px"></div>
</div>
</template>

<script>

import DeviceMsgHead from '@/views/deviceMQ/deviceMsg/deviceMsgHead'
import DeviceMsgFooter from '@/views/deviceMQ/deviceMsg/deviceMsgFooter'
import DeviceMsgCell from '@/views/deviceMQ/deviceMsg/deviceMsgCell'

export default {
  name: 's2Cell',
  components: { DeviceMsgCell, DeviceMsgFooter, DeviceMsgHead },
  props: {
    order: {
      type: String,
      default: () => {
        return '#'
      }
    },
    s2Info: {
      type: Object,
      default: () => {
        return {
          "baseStation": "",
          "battery": 0,
          "deviceMac": "",
          "g24Status": 0,
          "gnssstatus": 0,
          "id": "",
          "nbstatus": 0,
          "netStatus": 0,
          "rssi": 0,
          "status": 0,
          "type": 0,
          "wear": 0,
          "wifiCount": 0
        }
      }
    }
  },
  computed: {
    dataList() {
      return [
        "baseStation",
          "battery",
          "deviceMac",
          "g24Status",
          "gnssstatus",
          "nbstatus",
          "netStatus",
          "rssi",
          "status",
          "type",
          "wear",
          "wifiCount"
      ]
    }
  },
  methods: {
    dataMsg: function(key) {
      if (typeof this.s2Info[key] == 'undefined' || this.s2Info[key] == null) {
        return ''
      }
      return this.s2Info[key]
    }
  }
}
</script>

<style lang="scss" scoped>

.s2CellBGView {
  width: 100%;
}

</style>
