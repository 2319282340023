<template>
<div class="S2ViewBGView">
  <ul class="infinite-list-wrapper viewList" v-infinite-scroll="updateMsgList" style="overflow:scroll">
    <li v-for="(item, index) in dataList"
        class="infinite-list-item viewLi"
        :infinite-scroll-disabled="disabled" @click="clickItem(item)">
      <s2-cell :s2-info="item" :order="'#' + (index + 1)"></s2-cell>
    </li>
  </ul>
</div>
</template>

<script>

import S2Cell from '@/views/deviceMQ/S2View/s2Cell'
import MQmixin from '@/views/deviceMQ/MQmixin/MQmixin'

export default {
  name: 'S2View',
  components: { S2Cell },
  mixins: [MQmixin],
  data() {
    return {
      viewType:'S2',
      loadingDivId: 's2Loading',
      urlBlock: (config) => {
        return this.$api.deviceMQ.getS2MsgList(config)
      }
    }
  },
  methods:{
    clickItem:function(item) {
      this.$emit('clickItem', item);
    },
  }
}
</script>

<style lang="scss" scoped>

.S2ViewBGView {
  width: 100%;
  height: 100%;

  .viewList {
    width: calc(100%);
    //height: calc(100% - 40px);
    height: calc(100%);
    list-style: none;
    padding: 0;

    .viewLi {
      width: calc(100%);
      margin-bottom: 5px;
    }
  }
}

</style>
